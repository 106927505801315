/*
npx create-react-app timezone
cd timezone

npm install @mui/material @emotion/react @emotion/styled
npm install @fontsource/roboto
npm install @mui/icons-material
npm install luxon
npm install city-timezones
npm install react-beautiful-dnd
npm install react-redux
npm install @reduxjs/toolkit
npm install draft-js
npm install style-loader css-loader
npm install @draft-js-plugins/editor
npm install moment-timezone
npm install redux-persist
npm install react-router-dom
npm install feeder-react-feedback --force
npm install @ctrl/react-adsense --force
npm start
npm run build

--legacy-peer-deps
npm install --save source-map-explorer --legacy-peer-deps #then fix scripts, see https://create-react-app.dev/docs/analyzing-the-bundle-size/
npm install react-native-google-mobile-ads --legacy-peer-deps
*/

// Components:
// https://moment.github.io/luxon/#/install
// https://www.npmjs.com/package/city-timezones
// https://www.npmjs.com/package/react-beautiful-dnd
// https://github.com/transcrobes/mui-rte/tree/no-mui-styles
// https://www.npmjs.com/package/helmet

// Tutorials:
// https://www.robinwieruch.de/react-function-component
// https://www.kindacode.com/article/react-mui-create-dark-light-theme-toggle/
// https://egghead.io/courses/beautiful-and-accessible-drag-and-drop-with-react-beautiful-dnd
// https://medium.com/@clrksanford/persist-ence-is-key-using-redux-persist-to-store-your-state-in-localstorage-ac6a000aee63
// https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
// https://blog.bitsrc.io/start-collecting-feedback-from-users-across-your-react-apps-with-feeder-sh-5b2f6852e6e0

import "@fontsource/roboto"; // Loading Roboto font. MUI was designed with this font in mind.
import {
  AppBar,
  Container,
  Typography,
  CssBaseline,
  Divider,
  Box
} from "@mui/material";

import * as React from 'react';

import DraggableList from "./components/draggable_list";

import { CitySelector } from "./components/timezone_selector";
import { InitializeRedux } from "./components/timezone_selector";

import MyEditor from "./components/editor";
import TimeTable from "./components/time_table";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Component } from 'react'

import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet

import { TermsAndConditionsPG } from "./components/terms_and_conditions";
import { HelpPG } from "./components/help";

import { createMuiTheme, createTheme, ThemeProvider } from '@mui/material/styles';

import {Adsense} from '@ctrl/react-adsense';

import { useState, useEffect } from "react";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Link,
  Navigate,
  useLocation
} from "react-router-dom";

const SelectionPG = (props) => {
  return (
    <>
      <Container sx={{px:{xs:0, sm:2}}}>
        <Typography variant="h8">
          Enter a city
        </Typography>
        <CitySelector />
      </Container>

      <Divider  sx={{px:{xs:0, sm:2}, m:2}}/>

      <Container sx={{px:{xs:0, sm:2}}}>
        <DraggableList />
        <InitializeRedux />
      </Container>
    </>
  )
}


const TimeTablePG = (props) => {
  return (
    <>
      <SelectionPG />
      <Container sx={{px:{xs:0, sm:2}}}>
        <TimeTable />
      </Container>
    </>)
}

const TranslatorPG = (props) => {
  return (
    <>
      <SelectionPG />

      <Divider sx={{ margin: 2 }} />

      <Container sx={{px:{xs:0, sm:2}}}>
        <MyEditor />
      </Container>
    </>)
}

const SwissKnifePG = (props) => {
  return (
    <>
      <SelectionPG />

      <Divider sx={{ margin: 2 }} />

      <Container sx={{px:{xs:0, sm:2}}}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Time table</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TimeTable />
          </AccordionDetails>
        </Accordion>
      </Container>

      <Divider sx={{ margin: 2 }} />

      <Container sx={{px:{xs:0, sm:2}}}>
        <MyEditor />
      </Container>
    </>
  )
}

const ErrorPG = (props) => { return (<>Page not found. Go <Link to={`swiss_knife`}>home.</Link></>) }

const Root = (props) => {
  const location = useLocation();

  let outletOrSwissKnife = <Outlet />
  if (location.pathname === '/') {
    outletOrSwissKnife = <SwissKnifePG />
  }



  const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    });

    useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== "undefined") {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
};





  const size = useWindowSize();  


  return (
    <Box>
      <CssBaseline enableColorScheme />
      <AppBar position="relative">
        <Container sx={{px:{xs:0, sm:2}}}>
          <Typography variant="h6" noWrap>
            TZ PRO - Ultimate Time Zone Conversion Utility
          </Typography>
          <Typography variant="h8" noWrap>
            | <Link to={`/`}>Swiss knife </Link>
            | 
            {size.width <= 370 && <Link to={`time_table`}>TT </Link> }
            {size.width > 370 && <Link to={`time_table`}>Time table </Link> }
            | <Link to={`translator`}>Translator </Link>
            | <Link to={`help`}>Help </Link> |
            {size.width <= 530 && <Link to={`terms`}>T&#38;C </Link> }
            {size.width > 530 && <Link to={`terms`}>Terms and conditions </Link> }
            |
          </Typography>
        </Container>
      </AppBar>

      {outletOrSwissKnife}

      <Divider sx={{ margin: 2 }} />

      <Adsense client="ca-pub-8758768939767335"/>

      <Feedback projectId="63224450bcec77000438beb7" />
      <Divider sx={{ margin: 2 }} />

      <Divider sx={{ margin: 2 }} />

      <Divider sx={{ margin: 2 }} />
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Container sx={{px:{xs:0, sm:2}}}>
          <Typography variant="h8" noWrap>
            Email us: <a href="mailto: info@tzpro.org">info@tzpro.org</a>. Copyright 2022.
          </Typography>
        </Container>
      </AppBar>
    </Box>
  );
}


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPG />,
    children: [
      // {
      //   path: "/swiss_knife",
      //   element: <SwissKnifePG />,
      // },
      {
        path: "/time_table",
        element: <TimeTablePG />,
      },
      {
        path: "/translator",
        element: <TranslatorPG />,
      },
      {
        path: "/help",
        element: <HelpPG />,
      },
      {
        path: "/terms",
        element: <TermsAndConditionsPG />,
      },
    ],
  },
]);


const App = (props) => {
  const theme = createTheme();

  return (
    <Box>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <title>TZ PRO</title>
      <RouterProvider router={router} />
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    </Box>
  );
};

export default App;

