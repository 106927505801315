// import { SportsMartialArtsTwoTone } from "@mui/icons-material";
import { DateTime } from "luxon";

var moment = require('moment-timezone');

function parseTime( t ) {
    //console.log("tt", t)
    var d = new Date();
    var time = t.match( /(\d+)(?::(\d\d))?\s*(p?)/ );
    //console.log(time)
    if( time === null ) return null ;

    d.setHours( parseInt( time[1]) + (time[3] ? 12 : 0) );
    d.setMinutes( parseInt( time[2]) || 0 );
    d.setSeconds(0)

    // console.log(d)

    return d;
 }

 export const shortTZName = (TZname) => {
    // console.log('TZname', TZname) ;
    const mm = moment().tz(TZname).format('z');

    const isUnnamed = mm.match( /[+-]?\d+/ );
//    console.log({mm, isUnnamed, TZname})
    if( isUnnamed !== null ) 
        return 'GMT' + mm ;

    return mm ;
}


export const convertTimeTokensToOtherTZand24 = (token, sourceTZ, targetTZ, output24s, apmIncl) =>
{

    // console.log('ZZ', sourceTZ)
    const tt = parseTime(token) ;
    let dt = DateTime.now() ;
    dt = dt.setZone(sourceTZ) ;
    dt = dt.startOf('day') ;
    let xx = DateTime.fromJSDate(tt) ;
    dt = dt.plus({hours: xx.hour}).plus({minutes: xx.minute}) ;
    dt = dt.setZone(targetTZ) ;

    let output ;
    if(output24s) {
        output = dt.toFormat('H:mm') ;
    } else {
        if(apmIncl) {
            output = dt.toFormat('h:mm a').toLowerCase() ;
        } else {
            output = dt.toFormat('h:mm').toLowerCase() ;
        }
    }
    return [output, dt];
}

export const convertTimeTokensToOtherTZ = (token, sourceTZ, targetTZ, output24s, apmIncl) =>
{

    const [dt, hour24] = convertTimeTokensToOtherTZand24(token, sourceTZ, targetTZ, output24s, apmIncl) ;
    return dt ;

}

const toLowerCase = (record) => {
    record.lower = record.string.toLowerCase()
    return record ;
}

const extractTokens = (record) => {

    const tokenPstart = [0]  // Token pointers
    const tokenT = []   // Token types
    let tokenTs = ''    // String of token type abbriviations
    const tokenVal = [] // Token value

    let tmpString = record.string
    let pn = 0
    while( tmpString.length > 0 ) { // word, integer, diacritics, space, new line, other

        const processRegexp = (regexp, TT) => {
            let result = regexp.exec(tmpString);
            if( result ) {
                let res = result[1] ;
                pn += res.length ;
                tokenPstart.push(pn) ;
                tokenT.push(TT) ;
                tokenTs += TT.slice(0,1) ;
                tokenVal.push(res) ;
                tmpString = tmpString.slice(res.length)
                // console.log(res, TT, tokenTs, '>>>', res.charCodeAt(0), result, pn, tmpString)
                return true ;
            }
            return false ;
        }


        // ABCDEFGhIJklMNOPqrSTUvWxyz - Capital letters taken
        if( processRegexp(/((?:^or)|(?:^and))[^\w\d]/i, 'junction')) continue ; // j
        if( processRegexp(/((?:^[-–—]+)|(?:^to)|(?:^till)|(?:^until))/, 'to')) continue ; // t
        if( processRegexp(/(^noon)[^\w\d]/i, 'noon')) continue ; // n
        if( processRegexp(/(^midnight)[^\w\d]/i, 'good_midnight')) continue ; // g
        if( processRegexp(/(^\d+)/, 'int')) continue ; // i
        if( processRegexp(/(^a|^A)/, 'a')) continue ; // a
        if( processRegexp(/(^p|^P)/, 'p')) continue ; // p
        if( processRegexp(/(^m|^M)/, 'm')) continue ; // m
        if( processRegexp(/(^\w+)/, 'word')) continue ; // w
        if( processRegexp(/(^[.])/, 'dot')) continue ; // d
        if( processRegexp(/(^[:])/, 'column')) continue ; // c
        if( processRegexp(/(^[,;?!])/, 'other_punctation')) continue ; // o
        if( processRegexp(/(^\s*\r?(?:\n|\x01))/, 'end_of_line')) continue ; // e 
        if( processRegexp(/(^\s+)/, 'space')) continue ; // s
        if( processRegexp(/(^.)/, 'unknown')) continue ; // u
        //                         full_stop             // f
        //                         beat (TIME)           // b 
        break;
    }




    const tokenPend = tokenPstart.slice(1) ;
    record.tokenPstart = tokenPstart ;
    record.tokenPend = tokenPend ;
    record.tokenT = tokenT ;
    record.tokenTs = tokenTs ;
    record.tokenVal = tokenVal ;

    // console.log('REC1', record)

    return record ;
}

const splice = (str, start, deleteCount=undefined, item1=undefined, item2=undefined, itemN=undefined) => {
    const tmp = str.split('') ;
    tmp.splice(start, deleteCount, item1, item2, itemN) ;
    const newStr = tmp.join('') ;
    return newStr ;
}

const removeSpaces = (record) => {
    // console.log('Before remove spaces', record)
    const tokenPstart = record.tokenPstart ;
    const tokenPend = record.tokenPend ;
    const tokenT = record.tokenT ;
    const tokenVal = record.tokenVal ;

    for( let i = 0 ; i <= record.tokenPstart.length-1 ; ++i ) {
        if(tokenT[i] === 'space' || tokenT[i] === 'end_of_line') {
            tokenPstart.splice(i,1) ;
            tokenPend.splice(i,1) ;
            tokenT.splice(i,1) ;
            record.tokenTs = splice(record.tokenTs, i, 1)
            tokenVal.splice(i,1) ;
        }
    }
    // console.log('Spaces removed', record)
    return record ;
}

// https://stackoverflow.com/questions/1431094/how-do-i-replace-a-character-at-a-particular-index-in-javascript
// Not replacing in place, need to return the string?
String.prototype.replaceAt = function(index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}

const sentenceBoundaries = (record) => {

    // console.log(record)

    // const tokenPstart = record.tokenPstart ;
    // const tokenPend = record.tokenPend ;
    const tokenT = record.tokenT ;
    let tokenTs = record.tokenTs ;
    const tokenVal = record.tokenVal ;

    for( let i = 0 ; i < record.tokenPstart.length - 2; ++i ) { // -2 - not checking last full stop
        if(tokenT[i] === 'dot') {
            const tv = tokenVal[i+1] ;

            // console.log('~~~~', tokenT[i], tv)
            if( /^[A-Z]$/.test(tv.charAt(0)) ) {
                // console.log('YES')
                tokenT[i] = 'full_stop' ;
                tokenTs = tokenTs.replaceAt(i, 'f' );
                record.tokenTs = tokenTs
            }
        }
    }

    let i = record.tokenPstart.length-2 ;
    if(tokenT[i] === 'dot') {
        tokenT[i] = 'full_stop' ;
        tokenTs = tokenTs.replaceAt(i, 'f' );
        record.tokenTs = tokenTs
    }

    // console.log('tokenTs >>>> ', record.tokenTs)
    // for( let i = 0 ; i <= record.tokenPstart.length ; ++i ) {
    //     console.log(tokenT[i], tokenVal[i])
    // }

    return record ;
}

const extractTime = (record) => {
    // console.log(record)

    const tokenPstart = record.tokenPstart ;
    const tokenPend = record.tokenPend ;
    const tokenT = record.tokenT ;
    const tokenVal = record.tokenVal ;
    record.tokenBeat = [] ;
    const tokenBeat = record.tokenBeat ;

    let inc = 1 ;
    let alone = true ;
    for( let i = 0 ; i < tokenPstart.length - 1; i += 1 ) { 
        inc = 1 
        let val1, val2 ;
        if(tokenT[i] === 'noon' ) {
            val1 = 12
            val2 = 0
            alone = false ;
        } else if(tokenT[i] === 'good_midnight' ) {
            val1 = 0
            val2 = 0
            alone = false ;
        } else {
            if(tokenT[i] !== 'int') {
                // console.log(tokenVal[i], tokenT[i])
                continue ;
            }

            // A. one or two digits
            val1 = parseInt(tokenVal[i])
            const digits1 = tokenVal[i].length
            // a. alone
            val2 = 0
            // b. semicolumn minutes
            if( i < tokenPstart.length - 3 && tokenT[i+1] === 'column' && tokenT[i+2] === 'int') {
                val2 = parseInt(tokenVal[i+2])
                inc = 3 
                alone = false ;
            }

            // B. four digits // https://en.wikipedia.org/wiki/24-hour_clock#Military_time
            if(digits1 === 4) {
                const h = tokenVal[i].slice(0,2)
                const m = tokenVal[i].slice(2)
                val1 = parseInt(h)
                val2 = parseInt(m)
                inc = 1
                alone = false ;
            }

            // This is UGLY...
            let j = i + inc - 1
            const remainTs = record.tokenTs.slice(j+1)

            // console.log({remainTs})

            if(val1 < 0 || val1 >= 24) continue ;
            if(val2 < 0 || val2 > 60) continue ;
    
            // AM / PM
            const aaDist = /^[icjtons]*(a)d?(m)d?/.exec(remainTs)
            const ppDist = /^[icjtons]*(p)d?(m)d?/.exec(remainTs)
    
            if(val1 < 12 && ppDist !== null) val1 += 12
            if(val1 === 12 && ppDist !== null) val1 = 12
            if(val1 === 12 && aaDist !== null) val1 = 0
    
            if(ppDist !== null || aaDist !== null)
                alone = false ;

        }

        // console.log('val1, alone', val1, alone)
        if(alone) continue ;

        let j = i + inc - 1
        const remainTs = record.tokenTs.slice(j+1)

        /// NEXT STEP: MARK OF THE END OF THE TIME TOKEN = e.g. >1:30 pm<
        const aaNear = /^[ns]*(a)d?(m)d?/.exec(remainTs)
        const ppNear = /^[ns]*(p)d?(m)d?/.exec(remainTs)

        if( aaNear !== null ) {
            j += aaNear[0].length
            inc += aaNear[0].length
        }

        if( ppNear !== null ) {
            j += ppNear[0].length
            inc += ppNear[0].length
        }

        const start = tokenPstart[i]
        const end = tokenPend[j]
        const str = record.string.slice(start, end)

        // Modify the structure
        tokenPstart.splice(i+1,j-i       )
        tokenPend.splice  (i  ,j-i+1, end)

        record.tokenTs = splice(record.tokenTs, i, j-i+1, 'b')
        tokenT.splice  (i,j-i+1,'beat')
        tokenVal.splice(i,j-i+1, str)

        tokenBeat[i] = val1.toString() + ":" + val2.toString()
        if(val2 < 10) {
            tokenBeat[i] = val1.toString() + ":0" + val2.toString()
        }

        // console.log('>>>', val1, val2, ">"+str+"<", record.tokenTs, i, j+1)
        // console.log(record)
    }

    // console.log(record)

    return record ;
}


const detectGroup = (record) => {
    // console.log(record)

    const tokenPstart = record.tokenPstart ;
    const tokenPend = record.tokenPend ;
    const tokenT = record.tokenT ;
    record.tokenGroupEnd = [] ;
    const tokenGroupEnd = record.tokenGroupEnd ;

    let inc = 1 ;
    for( let i = 0 ; i < tokenPstart.length - 1; i += inc ) { 
        inc = 1 
        if(tokenT[i] !== 'beat') {
            // console.log(tokenVal[i], tokenT[i])
            continue ;
        }

        const remainTs = record.tokenTs.slice(i+1)
        const group = /^(?:[ojtn]*b)+/.exec(remainTs)
        // console.log('group', group)

        if( group !== null ) {
            // De-duplicate. There is a bug in editor.js copyOverAndHighlite function. Without duplicates it works better. 
            for(let j = 0 ; j < i ; j++) {
                const strStart1 = tokenPstart[j]
                const strEnd1 = tokenPend[tokenGroupEnd[j]]
                const str1 = record.string.slice(strStart1, strEnd1)

                const strStart2 = tokenPstart[i]
                const strEnd2 = tokenPend[i+inc-1+group[0].length]
                const str2 = record.string.slice(strStart2, strEnd2)

                if(str1 === str2) continue

            }

            inc += group[0].length
        }

        tokenGroupEnd[i] = i+inc-1
        // console.log('record.string.slice(tokenT[i],tokenGroupEnd[i])',record.string.slice(tokenPstart[i],tokenPend[tokenGroupEnd[i]]))
    }

    // console.log(record)

    return record ;
}

const translateGroup = (record) => {
    const tokenPstart = record.tokenPstart ;
    const tokenPend = record.tokenPend ;
    const tokenT = record.tokenT ;
    const tokenBeat = record.tokenBeat ;
    const tokenVal = record.tokenVal ;
    const tokenGroupEnd = record.tokenGroupEnd ;
    const targetTZs = record.targetTZs ;
    record.searchArray = [] ;
    const searchArray = record.searchArray ;
    record.searchArrayIdx = [] ;
    const searchArrayIdx = record.searchArrayIdx ;
    record.replaceArray = [] ;
    const replaceArray = record.replaceArray ;

    let inc = 1 ;
    for( let i = 0 ; i < tokenPstart.length - 1; i += inc ) { 
        inc = 1 
        if(tokenT[i] !== 'beat') {
            // console.log(tokenVal[i], tokenT[i])
            continue ;
        }
        const strStart = tokenPstart[i]
        const strEnd = tokenPend[tokenGroupEnd[i]]
        const str = record.string.slice(strStart, strEnd)
        searchArray.push(str)
        searchArrayIdx.push([strStart, strEnd])

        inc = tokenGroupEnd[i] - i + 1
        // console.log(str, inc)

        let tmp = ''
        for( let tz=0 ; tz < targetTZs.length; ++tz ) {
            // Flag duplicate am/pm (e.g. "1 am or 2 am" => "1 or 2 am")
            const apm = []
            const apmIncl = []
            for(let j = i ; j <= tokenGroupEnd[i] ; j += 1 ) { 
                if(tokenT[j] === 'beat') {
                    const xx = convertTimeTokensToOtherTZ(tokenBeat[j], record.sourceTZ, record.targetTZs[tz], record.output24s[tz], true)
                    apm.push(xx[xx.length-2]) // a or p from am/pm
                }
            }

            let lastApm = null
            for( let j = apm.length - 1 ; j >= 0 ; j--) {
                if(lastApm !== apm[j]) {
                    apmIncl[j] = true ;
                    lastApm = apm[j] ;
                } else {
                    apmIncl[j] = false ;
                    lastApm = apm[j] ;
                }
            }

            // console.log('apm', apm)
            // console.log('apmIncl', apmIncl)

            let apmIdx = 0
            for(let j = i ; j <= tokenGroupEnd[i] ; j += 1 ) { 
                if(tokenT[j] === 'beat') {
                    // console.log(tokenVal[j])
                    tmp += convertTimeTokensToOtherTZ(tokenBeat[j], record.sourceTZ, record.targetTZs[tz], record.output24s[tz], apmIncl[apmIdx])
                    apmIdx++
                    apm[j] = tmp[tmp.length-2] === 'a' || tmp[tmp.length-2] === 'p'
                } else {
                    tmp += record.string.slice(tokenPend[j-1], tokenPstart[j+1])
                }
            }
            tmp += " " + shortTZName(targetTZs[tz]) ;
            if(tz < targetTZs.length - 1 ) tmp += " / " ;
        }
        // console.log(tmp)
        replaceArray.push(tmp)
    }

    // console.log(record) ;

    return record ;
}

export const extractSearchAndReplaceTokens = (string, sourceTZ, targetTZs, output24s) => {

    let record = {string: string, sourceTZ: sourceTZ, targetTZs: targetTZs, output24s: output24s}
    record = toLowerCase(record) 
    record = extractTokens(record) // word, number, diacritics, space, new line.
    record = removeSpaces(record)
    record = sentenceBoundaries(record) // full_stop

    record = extractTime(record) // tokenBeat
    record = detectGroup(record) // group to translate
    record = translateGroup(record) // translate group

    return [record.searchArrayIdx, record.replaceArray] ;
}

