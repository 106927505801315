// import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card,
  ListItem,
  ListItemText,
  ListItemIcon,
  RadioGroup,
  Radio,
  Switch,
  Checkbox,
  Typography
} from "@mui/material";

import * as React from 'react';
import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import SourceIcon from '@mui/icons-material/Source';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import { useState } from "react";
import IconButton from '@mui/material/IconButton';

import { useStore } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from "luxon";
import { useEffect, useState } from 'react';
import { shortTZName } from './time_operations'
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
// import { MaxAdContentRating } from "react-native-google-mobile-ads";
import Tooltip from '@mui/material/Tooltip';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Drag drop
//


const Quote = ({ quote, index }) => {
  const dispatch = useDispatch();

  const [dateTime, setDateTime] = useState(DateTime.now());

  useEffect(() => {
    const interval = setInterval(() => { setDateTime(DateTime.now()); }, 1000);
    return () => clearInterval(interval);
  }, [dateTime]);


  let hide_output = true;
  if (quote?.hide_output)
    hide_output = false;


  const MyTime = (quote) => {
    if (quote.output24)
      return (
        <Typography variant="h8">{dateTime.setZone(quote.timezone).toFormat('H:mm') + "\t" + shortTZName(quote.timezone)}</Typography>
      );
    else
      return (
        <Typography variant="h8">{dateTime.setZone(quote.timezone).toFormat('h:mm a') + "\t" + shortTZName(quote.timezone)}</Typography>
      );
    //<Typography variant="h4">{DateTime.now().setZone(quote.timezone).quote?.output24?toFormat('H:mm'):toFormat('h:mm a').toLowerCase()}</Typography>
  }

  return (
    <>
      <Draggable draggableId={index.toString()} index={index}>
        {provided => (
          <Card ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{ mx: 0, my: 1, p: 0 }}
          >
            <ListItem sx={{ mx: { xs: 0, sm: 1, md: 2 }, my: 1, p: 0, display: 'flex', justifyContent: 'space-between' }}>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50 }}>
                <DehazeIcon />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="left" sx={{ width: { xs: 140, sm: 150, md: 200 }, height: 50 }}>
                <Typography noWrap>
                  <b>{quote.city}</b>{<br />}
                </Typography>
                {/*<Typography noWrap sx={{variant: {xs: "body2", sm: "h4"}}}>*/}
                <Typography noWrap>
                  {quote.timezone}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="left" sx={{ width: { xs: 70, sm: 110, md: 150 }, height: 50 }}>
                <MyTime timezone={quote.timezone} output24={quote?.output24} />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50 /* backgroundColor: 'primary.dark'*/ }}>
                <Radio
                  onChange={(x) => {
                    dispatch({ type: 'timezone/select_input', payload: index })
                  }}
                  checked={quote?.select_input}
                />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50 }}>
                <Switch
                  onChange={(x) => {
                    dispatch({ type: 'timezone/hide_output', payload: index })
                  }}
                  checked={hide_output}
                />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50 }}>
                <Checkbox
                  onChange={(x) => {
                    dispatch({ type: 'timezone/set_output24', payload: index })
                  }}
                  checked={quote?.output24}
                />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50 }}>
                <IconButton id='xx' aria-label="delete" onClick={(x) => {
                  dispatch({ type: 'timezone/del', payload: index })
                }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </ListItem>
          </Card>
        )}
      </Draggable>
    </>
  );
}


const QuoteList = React.memo(
  ({ quotes }) => {
    if (!quotes) {
      return;
    }

    return quotes.map((quote: QuoteType, index: number) => (
      <Quote quote={quote} index={index} key={index} />
    )
    );
  }
);

const DraggableList = () => {
  useSelector((state) => state?.cities)
  const store = useStore()
  const cities = store.getState()?.cities
  const dispatch = useDispatch();

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    dispatch({ type: 'timezone/swap', payload: [result.source.index, result.destination.index] });
  }

  return (
    <Box>
      <Card sx={{ my: 1, p: 0, mx: { sx: 0, md: 1 } }}>
        <ListItem sx={{ mx: { xs: 0, sm: 1, md: 2 }, my: 1, p: 0, display: 'flex', justifyContent: 'space-between' }}>
          <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50, minWidth: { xs: 30, sm: 40, md: 70 } /*backgroundColor: 'primary.dark'*/ }}>
            <Tooltip title="Drag and drop to change order of time zones">
              <ShuffleIcon />
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="left" sx={{ width: { xs: 140, sm: 150, md: 200 }, height: 50, minWidth: { xs: 140, sm: 150, md: 200 } }}>
            <Typography noWrap>
              Time zone
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="left" sx={{ width: { xs: 70, sm: 110, md: 150 }, height: 50, minWidth: { xs: 70, sm: 110, md: 150 } }}>
            <Typography noWrap>
              Time now
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50, minWidth: { xs: 30, sm: 40, md: 70 } }}>
            <Tooltip title="Source time zone">
              <SourceIcon />
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50, minWidth: { xs: 30, sm: 40, md: 70 } }}>
            <Tooltip title="Hide output of given TZ">
              <VisibilityIcon />
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50, minWidth: { xs: 30, sm: 40, md: 70 } }}>
            <Tooltip title="24h vs. am/pm system">
              <Typography width="50">
                24h
              </Typography>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center" sx={{ width: { xs: 30, sm: 40, md: 70 }, height: 50, minWidth: { xs: 30, sm: 40, md: 70 } }}>
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          </Box>
        </ListItem>
      </Card>
      <Card sx={{ my: 1, p: 0, mx: { sx: 0, md: 1 } }}>
        <RadioGroup
          aria-labelledby="xxx1"
          defaultValue="xxx2"
          name="radio-buttons-group">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <QuoteList quotes={cities} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </RadioGroup>
      </Card>
    </Box>
  );
}

export default DraggableList;
