import React from 'react';
import {
    Editor, 
    EditorState, 
    ContentState,
    CompositeDecorator,
    SelectionState,
    convertFromRaw, 
    convertToRaw,
    Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';

import {
    AppBar,
    Container,
    Typography,
    CssBaseline,
    Divider,
    Box
  } from "@mui/material";

import {
    Button  } from "@mui/material";

import { getSourceAndTagetTZs } from './timezone_selector' ;

import { extractSearchAndReplaceTokens } from './time_operations'

import { useStore } from 'react-redux';

export default function MyEditor() {
    const initialText = 
    'See you tomorrow from 2 to 3 pm.\n'+ 
    'Or earlier, around noon.'

////////////////////////////////////////////////////////////////////
// Highlight

    function findWithRegex(words, contentBlock, callback) {
        const text = contentBlock.getText();

        words.forEach(word => {
            const matches = [...text.matchAll(word)];
            matches.forEach(match =>
                callback(match.index, match.index + match[0].length)
            );
        });
    }

    const styles = {
        editor: {
            border: "1px solid gray",
            minHeight: "6em"
        }
    };

    const createDecorator = (highlits) => {       
    
        function handleStrategy(contentBlock, callback) {
            findWithRegex(highlits, contentBlock, callback);
        }
    
        return new CompositeDecorator([
            {
            strategy: handleStrategy,
            component: Decorated
            }
        ]);
    }

    const Decorated = ({ children }) => {
        return <span style={{ background: "yellow" }}>{children}</span>;
    };


    const [editorState1, setEditorState1] = React.useState(
        // EditorState.createEmpty()
        EditorState.createWithContent(ContentState.createFromText(initialText))
    );

    const [editorState2, setEditorState2] = React.useState(
        EditorState.createEmpty()//createDecorator())
    );

    
////////////////////////////////////////////////////////////////////
// Copy over
    const store = useStore();

    const copyOverAndHighlite = () => { 
        const current_content = editorState1.getCurrentContent() ;

        let state2 = convertFromRaw(convertToRaw(current_content));
        const text2 = current_content.getPlainText('\u0001')
        // console.log(text2)

        const cities = store.getState()?.cities ;
    
        const [sourceTZ, targetTZs, output24s] = getSourceAndTagetTZs(cities);
        const [search_array_idx, replace_array] = extractSearchAndReplaceTokens(text2, sourceTZ, targetTZs, output24s) ;
       
        const highlits = replace_array

        state2 = EditorState.createWithContent(state2)

        // console.log('search_array_idx', search_array_idx)

        //
        // https://stackoverflow.com/questions/48286946/programmatically-jump-to-a-specific-block-and-move-the-focus-caret-to-a-decorate
        // https://stackoverflow.com/questions/41353569/get-caret-position-line-number-in-draft-js
        // https://stackoverflow.com/questions/70278376/how-can-i-scroll-to-specific-section

// Play from here:
        for(let i = search_array_idx.length-1 ; i >=0 ; --i ) { // Go backwards, as it change length of the string while processing.
            const replace = replace_array[i] ;
            const idx = search_array_idx[i] ;

            const selectionsToReplace = [];
            const blockMap = state2.getCurrentContent().getBlockMap();
        
            let blockStart = 0
            let blockEnd = 0

            blockMap.forEach(contentBlock => {
                    const blockKey = contentBlock.getKey();
                    blockStart = blockEnd ;
                    blockEnd += contentBlock.getLength()+1;

                    let blockSelection = SelectionState.createEmpty(blockKey)

                    if( (idx[0] >= blockStart ) && (idx[0] <= blockEnd) ) {
                        const sel = {
                            anchorOffset: idx[0]-blockStart,
                            focusOffset: Math.min(idx[1]-blockStart, blockEnd)
                        }
                        blockSelection = blockSelection.merge(sel);
                        selectionsToReplace.push(blockSelection)
                    }        
            })

            let contentState = state2.getCurrentContent();

            selectionsToReplace.forEach(selectionState => {
                contentState = Modifier.replaceText(
                    contentState,
                    selectionState,
                    replace,
                    )
                }
            );
        
            state2 = EditorState.createWithContent( contentState );
        }

        state2 = EditorState.set( state2, {decorator: createDecorator(highlits)} );

        setEditorState2(state2);

        return
    }

    const unHighlite = () => { 

        let state2 = editorState2.getCurrentContent() ;       
        state2 = EditorState.createWithContent( state2 );

        setEditorState2(state2);

        return

    }

    const copyToClipboard = () => {
        const current_content = editorState2.getCurrentContent() ;
        const text3 = current_content.getPlainText()
//        console.log({text3})
        navigator.clipboard.writeText(text3)
    }

////////////////////////////////////////////////////////////////////
// Component
    const editor1 = React.useRef(null);
    const editor2 = React.useRef(null);

    function focusEditor1() {
        editor1.current.focus();
    }

    function focusEditor2() {
        editor2.current.focus();
    }

    React.useEffect(()=>{
        const current_content = editorState2.getCurrentContent() ;
        const text2 = current_content.getPlainText('\u0001')
        if(text2.length===0)
            copyOverAndHighlite()
    })

    return (
        <>
            <div onClick={focusEditor1} style={styles.editor}>
                <Editor
                    ref={editor1}
                    editorState={editorState1}
                    onChange={editorState1 => setEditorState1(editorState1)}
                />
            </div>

            <Box sx={{  display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" onClick={copyOverAndHighlite} sx={{ width: {xs: 0.25, sm:150, md:230 }, height: {xs: 80, sm:60, md:30 }, m:1}}>Translate and Highlite</Button>
                <Button variant="contained" onClick={unHighlite}          sx={{ width: {xs: 0.25, sm:150, md:230 }, height: {xs: 80, sm:60, md:30 }, m:1}}>Unhighlite</Button>
                <Button variant="contained" onClick={copyToClipboard}     sx={{ width: {xs: 0.25, sm:150, md:230 }, height: {xs: 80, sm:60, md:30 }, m:1}}>Copy to clipboard</Button>
            </Box>

            <div onClick={focusEditor2} style={styles.editor}>
                <Editor
                    ref={editor2}
                    editorState={editorState2}
                    onChange={editorState2 => setEditorState2(editorState2)}
                />
            </div>
        </>
    );
}

