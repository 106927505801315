import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';

import App from './App';
//import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
// import { store } from './components/redux';

import { PersistGate } from 'redux-persist/lib/integration/react';
// import the two exports from the last code snippet.
import { persistor, store } from './components/redux';
// import your necessary custom components.
// import { RootComponent, LoadingView } from './components/redux';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App/>
    </PersistGate>
</Provider>,
);
