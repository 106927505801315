import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useStore } from 'react-redux'
import { shortTZName, convertTimeTokensToOtherTZand24 } from './time_operations'
import { useSelector } from 'react-redux';

import { createMuiTheme, createTheme, ThemeProvider, makeStyles } from '@mui/material/styles';

const MyTableHead = (props) => {
    if (!Array.isArray(props.table)) return (<></>);
    return (
        <TableHead>
            <TableRow>
                <MyTableHeadCells head={props.table} header0={props.header0} header1={props.header1} />
            </TableRow>
        </TableHead>
    );
}

const MyTableHeadCells = (props) => {
    return (props.head.map((x, j) => (<TableCell key={props.header0[j] + props.header1[j]} align="center">{x}</TableCell>)));
}

const MyTableBody = (props) => {
    if (!Array.isArray(props.table)) return (<></>);
    return (
        <TableBody>
            {
                props.table.map((x, i) => (<TableRow key={i} hover
                    sx={{ // https://stackoverflow.com/a/73168469
                        "&:hover .MuiTableCell-root, &:hover .MuiTableCell-root span.material-icons-outlined": {
                          color: "black",
                          fontWeight: "bold",
                          backgroundColor: "primary.light"
                        }
                      }}              
                ><MyTableBodyCells row={x} i={i} header0={props.header0} header1={props.header1} /></TableRow>))
            }
        </TableBody>
    );
}

const MyTableBodyCells = (props) => {
    return (props.row.map((x, j) => (<TableCell key={props.header0[j] + props.header1[j] + x.timeMessage} align="center" sx={{ backgroundColor: x.color }}>{x.timeMessage}</TableCell>)));
}

const getTimeTable = (cities) => {

    if (!Array.isArray(cities)) return [[]]

    const result = []
    const city_names = []
    const tz_shorts = []



    for (let i = 0; i < cities?.length; ++i) {
        const city = cities[i];
        city_names.push(city.city);
        tz_shorts.push(shortTZName(city.timezone))
    }

    result.push(city_names)
    result.push(tz_shorts)

    for (let h = 0; h <= 24; ++h) {
        const token = h.toString() + ":00";
        // console.log('token', token)
        const sourceTZ = cities[0].timezone;
        const times = [];
        for (let i = 0; i < cities?.length; ++i) {
            const city = cities[i];

            let output24s;
            if (city?.output24) {
                output24s = true;
            } else {
                output24s = false;
            }

            const s = {};
            let hour24;
            [s.timeMessage, hour24] = convertTimeTokensToOtherTZand24(token, sourceTZ, city.timezone, output24s, true);
            s.color = '#ffffff';
            if (hour24.hour > 17) s.color = '#bbbbbb';
            if (hour24.hour < 9) s.color = '#bbbbbb';
            if (hour24.hour < 8) s.color = '#717171';
            if (hour24.hour > 20) s.color = '#717171';

            times.push(s);
        }
        result.push(times);
    }

    return result;

}

const TimeTable = () => {
    useSelector((state) => state?.cities)

    const store = useStore()
    const cities = store.getState()?.cities

    if (!Array.isArray(cities)) return (<></>);
    if (cities.length === 0) return (<></>);

    let table = getTimeTable(cities);

    const styles = theme => ({
        root: {
          width: "100%",
          marginTop: theme.spacing.unit * 3,
          overflowX: "auto"
        },
        table: {
          minWidth: 700
        },
        tableRow: {
          "&$hover:hover": {
            backgroundColor: "blue"
          }
        },
        tableCell: {
          "$hover:hover &": {
            color: "pink"
          }
        },
        hover: {}
      });

    return (
        <TableContainer component={Paper} sx={{ px: { xs: 0, sm: 2 } }}>
            <Table size="small" padding="none" sx={{ px: { xs: 0, sm: 2 } }}>
                <MyTableHead table={table[0]} header0={table[0]} header1={table[1]} />
                <MyTableHead table={table[1]} header0={table[0]} header1={table[1]} />
                <MyTableBody table={table.slice(2)} header0={table[0]} header1={table[1]} />
            </Table>
        </TableContainer>
    );
}

export default TimeTable;