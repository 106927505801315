import { createStore } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit'

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
//import rootReducer from './reducers'; // the value from combineReducers


export const add = createAction('timezone/add');
export const del = createAction('timezone/del');
const swap = createAction('timezone/swap');
const select_input = createAction('timezone/select_input');
const hide_output = createAction('timezone/hide_output');
const set_output24 = createAction('timezone/set_output24');

const timezoneReducer = createReducer(0, (builder) => {
  const fix_select_input = (state) => {
    const new_state = JSON.parse(JSON.stringify(state));
    let counter = 0

    if( new_state.cities.length === 0 ) return new_state ;

    for(let i = 0 ; i < new_state.cities.length ; ++i ) {
      if(new_state.cities[i]?.select_input) { 
        counter++ ; 
      }
    }

    if(counter !== 1) {
      for(let i = 0 ; i < new_state.cities.length ; ++i ) {
        new_state.cities[i].select_input = undefined
      }
      new_state.cities[0].select_input = true
    }

    return new_state ;
  }

  ////////////////////////////////////////////////////////////
  // Add new city
  builder.addCase(add, (state, action) => {
    const new_state = JSON.parse(JSON.stringify(state));
    if(!Array.isArray(new_state?.cities)) {
      new_state.cities = [] ;
    }

    if( (new_state.cities.length === 0) ||
      (new_state.cities.length > 0 &&
        JSON.stringify(new_state.cities[new_state.cities.length-1]) !==
        JSON.stringify(action.payload)
        ) ) {
      action.payload.output24 = false ;
      new_state.cities.push(action.payload);
    }

    return fix_select_input(new_state) ;
  });

  ////////////////////////////////////////////////////////////
  // Delete city
  builder.addCase(del, (state, action) => {
    const new_state = JSON.parse(JSON.stringify(state));
    if(new_state?.cities.length > action.payload) {
      new_state.cities.splice(action.payload,1)
      return fix_select_input(new_state) ;
    }
    console.error('Unable to remove element');
    return fix_select_input(new_state) ;
  });

  ////////////////////////////////////////////////////////////
  // Swap
  builder.addCase(swap, (state, action) => {
    const new_state = JSON.parse(JSON.stringify(state));
    if(new_state?.cities.length > Math.max(action.payload[0],action.payload[1]) ) {
      // console.log('qwr', action.payload)
      const startIndex = action.payload[0];
      const endIndex = action.payload[1];

      const [removed] = new_state.cities.splice(startIndex, 1);
      new_state.cities.splice(endIndex, 0, removed);
  
      // [new_state.cities[action.payload[0]], new_state.cities[action.payload[1]]] = 
      //   [new_state.cities[action.payload[1]], new_state.cities[action.payload[0]]] ;
      return new_state ;
    }
    console.error('Unable to swap elements');
    return new_state ;
  });

  ////////////////////////////////////////////////////////////
  // Select_input
  builder.addCase(select_input, (state, action) => {
    const new_state = JSON.parse(JSON.stringify(state));
    for(let i = 0 ; i < new_state.cities.length ; ++i ) {
      new_state.cities[i].select_input = undefined
    }
    new_state.cities[action.payload].select_input = true
    return new_state ;
  });

  ////////////////////////////////////////////////////////////
  // Hide output
  builder.addCase(hide_output, (state, action) => {
    const new_state = JSON.parse(JSON.stringify(state));

    if(new_state.cities[action.payload]?.hide_output)
      new_state.cities[action.payload].hide_output = false ;
    else
      new_state.cities[action.payload].hide_output = true ;

    return new_state ;
  });

  ////////////////////////////////////////////////////////////
  // set_output24
  builder.addCase(set_output24, (state, action) => {
    const new_state = JSON.parse(JSON.stringify(state));

    // console.log('state, action', state, action)

    if(new_state.cities[action.payload]?.output24)
      new_state.cities[action.payload].output24 = false ;
    else
      new_state.cities[action.payload].output24 = true ;

  // console.log('new_state.cities[action.payload].output24', new_state.cities[action.payload].output24)

    return new_state ;
  });

})

export const mapStateToProps = (state) => ({
  timezone: state.timezone
});

export const mapDispatchToProps = {
  add,
  del
};

//////////////////////////////////////////////////////////////////

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
 };
 
 const pReducer = persistReducer(persistConfig, timezoneReducer);
 
 export const store = createStore(pReducer);
 export const persistor = persistStore(store);
 