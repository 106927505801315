import * as React from 'react';
import {
  Typography,
  Button
} from "@mui/material";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useStore } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Logic
//
var cityTimezones = require('city-timezones');

function add_print_string(list)
{
  const city_list = JSON.parse(JSON.stringify(list))

  for( let i = 0 ; i < city_list.length ; ++i ) {

    if(city_list[i].city === city_list[i].province) {
      city_list[i].print_string = city_list[i].city +" / "+ city_list[i].country +" ➡️ "+ city_list[i].timezone;
    } else {
      city_list[i].print_string = city_list[i].city +" / "+ city_list[i].province +" / "+ city_list[i].country  +" ➡️ "+ city_list[i].timezone;
    }
  }

  return city_list ;
}

function findFromCityStateProvince(input_str)
{
// The same function as cityTimezones.findFromCityStateProvince(input_str),
// but returns sorted list 1. matched cities 2. decreasing sorted by population.

  input_str = input_str.replace('➡️', '')
  input_str = input_str.replace('/', '')

  const raw_list = cityTimezones.findFromCityStateProvince(input_str) ;
  const string_list = add_print_string(raw_list) ;

  const by_city = []
  const not_by_city = []
  
  const input_split = input_str.toLowerCase().split(" ");

  for( let i = 0 ; i < string_list.length ; ++i ) {
    let el = string_list[i];
    let is_by_city = true

    for( let j = 0 ; j < input_split.length ; ++j ) {
      let subs = input_split[j] ;

      if( el.city.toLowerCase().replace(/[^0-9a-z]/gi, '').indexOf(subs) === -1 ) {
        is_by_city = false
      }
    }

    if( is_by_city ) {
      by_city.push(el)
    } else {
      not_by_city.push(el)
    }      
  }    
  
  const srt = (b,a) => a.pop - b.pop ;
  
  const by_city_sorted = by_city.sort(srt)
  const not_by_city_sorted = not_by_city.sort(srt)

  const concat_list = by_city_sorted.concat(not_by_city_sorted)

  return concat_list.slice(0,10) ;
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Input time zone

export const CitySelector = () => {
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    if (inputValue === ''  || inputValue.length <= 1) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let newOptions = []
    //console.log('>>>', inputValue, '<<<')
    try{
      newOptions = findFromCityStateProvince(inputValue) ;
    } catch(error){
      console.log(error)  
    }
    //console.log('XXX')

    setOptions(newOptions);
  }, [value, inputValue]);
  
  // console.log('value');
  // console.log(value);
  const dispatch = useDispatch();
  React.useEffect(()=>{
    if(value) {
      dispatch({type: 'timezone/add', payload: value})
    }
    setValue(null) ; // Delete name from selector once selected. 
  },[value]);

  // console.log('inputValue');
  // console.log(inputValue);

  return(
  <> {/*
        <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
        <div>{`inputValue: '${inputValue}'`}</div>
      */}
      <Autocomplete
        options={options}
        renderInput={(params) => {
          // console.log('renderInput', params)
          return(<TextField {...params} label="Add a location" fullWidth />)
        }}
        autoComplete
        autoHighlight
        autoSelect
        clearOnBlur
        clearOnEscape
        fullWidth
        filterOptions={(x) => x}
        getOptionLabel={(option) => typeof option === 'string' ? option : option.print_string }
        selectOnFocus

        openText=""
        value={value}
        onChange={(event, newValue) => {
          //console.log('OnChangeLog', newValue)
          setOptions(newValue ? [newValue, ...options] : options);
          // setValue(newValue);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          //console.log('OnInputChangeLog', newInputValue)
          setInputValue(newInputValue);
        }}
        isOptionEqualToValue={(a,b)=>{return JSON.stringify(a)===JSON.stringify(b)}} 
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Typography variant="body2">
                {option.print_string}
              </Typography>
            </li>
          );
        }}
      />
  </>
  ) ;
}


//////////////////////////////////////////////////////////////////////////////////

export const getSourceAndTagetTZs = (cities) => {

  // console.log('cities', cities);

  let sourceTZ = ""
  const targetTZs = []
  const output24s = []

  if(cities === undefined) return [sourceTZ, targetTZs, output24s];

  for(let i =0 ; i < cities.length ; ++i ) {
      const city = cities[i] ;
      if( city?.select_input ) sourceTZ = city.timezone ;

      if( city?.hide_output ) continue ;
      targetTZs.push( city.timezone ) ;
      if( city?.output24 ) {
        output24s.push( true ) ;
      } else {
        output24s.push( false ) ;
      }
  }

  return [sourceTZ, targetTZs, output24s];
}


export const InitializeRedux = () => {
  useSelector((state) => state?.cities)
  const store = useStore()
  const cities = store.getState()?.cities
  const dispatch = useDispatch();

  const initialize = () => {
    const items = cities?.length
    for(let i = 0 ; i < items ; ++i ) {
      // console.log('Deleting', i)
      dispatch({type: 'timezone/del', payload: 0})
    }
    let newOptions = findFromCityStateProvince('Boston')
    let newOption = newOptions[0]
    dispatch({type: 'timezone/add', payload: newOption})
    newOptions = findFromCityStateProvince('Los Angeles')
    newOption = newOptions[0]
    // console.log('newOptions', newOption)
    dispatch({type: 'timezone/add', payload: newOption})
    newOptions = findFromCityStateProvince('New Delhi')
    newOption = newOptions[0]
    // console.log('newOptions', newOption)
    dispatch({type: 'timezone/add', payload: newOption})
   }

  const autoInitialize = () => {  
    // console.log('cities', cities)
    // console.log('Initializing?')
    if(cities === undefined) {
      // console.log('Initializing!')
      initialize() ;  
    }
  }

  React.useEffect(()=>{
    autoInitialize() ;
  })

  return (<Button variant="contained"  size="small" startIcon={
    <Tooltip title="Re-initialize time zone list">
      <DeleteIcon />
    </Tooltip>    
      } onClick={initialize}/>)


  // const dispatch = useDispatch();
  // const value = 123
  // dispatch({type: 'timezone/add', payload: value})
}


