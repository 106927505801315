import {
    Container,
  } from "@mui/material";

export const HelpPG = (props) => {return(<>
<Container>
<h2><strong>Help</strong></h2>

<p>Welcome to TZ Pro!</p>

<p>
Have you ever had a problem pinging your colleagues in different time zones when they 
will be available? You would write:
<code> Hey guys, are you available tomorrow at 9 am, or Thursday 10 - 11 
    am or 2–4 pm?</code>
</p>

<p>
You are in Boston. One guy is in L.A., the second in Paris, and the third in 
India. You look at the world clock, trying to figure out the time in each time zone.
</p>

<p>
That's why we developed TZ Pro. One click, and you copy/paste the message:
<br/>
<code>
Hey guys, are you available tomorrow 15:00 CEST / 6:00 am PDT / 6:30 pm IST, 
or Thursday 16:00 - 17:00 or 20:00-22:00 CEST / 7:00 - 8:00 or 11:00 am-1:00 pm 
PDT / 7:30 - 8:30 or 11:30 pm-1:30 am IST?
</code>
</p>

<p>
What can we do:
</p>
<ul>
<li>All time zones across the globe</li>
<li>AM/PM vs. 24 hours vs. military 4-digit format</li>
<li>Reordering - drag and drop the list</li>
<li>Automatic translation</li>
<li>Keeps settings if you come back later</li>
</ul>

<p>
Let us know, what do you think. The feedback button it at the end of each page.
</p>

<code>
#TimeZone #TimeZones #TimeZoneConverter #WorldClock 
</code>


</Container>

</>)}
